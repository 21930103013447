import * as React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import {MarkdownRemark} from '../models/markdown-remark';
import {PartnersList} from '../components/shared/partners';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {TitleSection} from '../components/pricing/title-section';
import {Benefits} from '../components/pricing/benefits';
import {FaqContainer} from '../components/pricing/faq';
import {BackgroundFigure} from '../components/shared/background-figure';
import {RegularSectionModel} from '../models/regular-section-model';
import {PricingFactors} from '../models/pricing-factors';
import { RegularSection } from '../components/shared/regular-section';

interface PricingPageData extends BasePageData {
  pricing: MarkdownRemark<{
    seoTitle: string;
    title: string;
    pricingTitle: string;
    pricingSubText: string;
    formTitle: string;
    moduleSelectorTitle: string;
    priceFactorsTitle: string;
    hubSpotFormId: string;
    buttonText: string;
    pricingFactors: Array<PricingFactors>;
    pricingSubSections: Array<{
      type: 'whatYouGet';
      Label: string;
      title: string;
      offerings?: Array<{
        offer: {
          title: string;
          text: string;
          altImage: string;
          image: string;
        }
      }>
    } | {
      type: 'faq';
      Label: string;
      title: string;
      questions?: Array<{
        question: {
          questionLabel: string;
          text: string;
        }
      }>
    } | {
      type: 'partners';
      sectionTitle: string;
      partnerLogos: Array<{
        logo: string;
        altText: string;
      }>
    } | RegularSectionModel>
  }>;
}

const PricingPage = ({data}: {data: PricingPageData}): JSX.Element => {

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.pricing.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
      />
      <div className={'desktop-only'}>
        <div style={{
          width: 68,
          height: 68,
          position: 'absolute',
          top: 636,
          zIndex: 1,
          transform: 'rotate(191deg)',
          left: 'calc(50% - 499px)'
        }}>
          <BackgroundFigure type={'triangle'} color={'#31ead2'}/>
        </div>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 77, height: 203}}
          position={{top: 578, left: 'calc(50% + 486px)'}}
          style={{zIndex: 1}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 18, height: 36}}
          position={{right: 0, top: 1100}}
          style={{
            zIndex: 0,
            borderRadius: 18,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#c4c3f6'}
          size={{width: 36, height: 72}}
          position={{left: 'calc(50% + 475px)', top: 1127}}
          style={{
            zIndex: 0,
            borderRadius: 36,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#00e0dd'}
          size={{width: 42, height: 84}}
          position={{right: 0, top: 2147}}
          style={{
            zIndex: 0,
            borderRadius: 42,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <div style={{
          position: 'absolute',
          left: -10,
          top: 2360,
          zIndex: 1,
          width: 116,
          height: 155,
          overflow: 'hidden',
          transform: 'rotate(113deg)',
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#eaedfd'}
            size={{width: 80, height: 80}}
            position={{right: 20, bottom: -10}}
          />
        </div>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 77, height: 203}}
          position={{top: 2612, right: 0}}
          style={{zIndex: 1}}
        />
      </div>
      <TitleSection
        header={data.pricing.edges[0].node.frontmatter.title}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        pricingFactors={data.pricing.edges[0].node.frontmatter.pricingFactors}
        formTitle={data.pricing.edges[0].node.frontmatter.formTitle}
        moduleSelectorTitle={data.pricing.edges[0].node.frontmatter.moduleSelectorTitle}
        priceFactorsTitle={data.pricing.edges[0].node.frontmatter.priceFactorsTitle}
        pricingSubText={data.pricing.edges[0].node.frontmatter.pricingSubText}
        pricingTitle={data.pricing.edges[0].node.frontmatter.pricingTitle}
        buttonText={data.pricing.edges[0].node.frontmatter.buttonText}
        hubSpotFormId={data.pricing.edges[0].node.frontmatter.hubSpotFormId}
        hubSpotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
      />
      <div style={{position: 'relative'}}>
        {
          data.pricing.edges[0].node.frontmatter.pricingSubSections.map((section, index) => {
            switch (section.type) {
              case 'partners': {
                return (
                  <PartnersList
                    key={index}
                    title={section.sectionTitle}
                    logos={section.partnerLogos}
                  />
                );
              }
              case 'faq': {
                return (
                  <FaqContainer
                    key={index}
                    title={section.title}
                    label={section.Label}
                    questions={section.questions?.map(q => q.question)}
                  />
                );
              }
              case 'whatYouGet': {
                return (
                  <Benefits
                    key={index}
                    title={section.title ?? ''}
                    label={section.Label ?? ''}
                    offers={section.offerings?.map(o => o.offer)}
                  />
                );
              }
              case 'regular': {
                return (
                  <RegularSection key={index} {...section.section} />
                );
              }
              default:
                return '';
            }
          })
        }
        <div className={'desktop-only'}>
          <BackgroundFigure
            type={'dots'}
            color={''}
            size={{width: 969, height: 739}}
            position={{left: 'calc(50% - 700px)', top: -100}}
          />
        </div>
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default PricingPage;

export const pageQuery = graphql`
  {
    ...BaseData
    pricing: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/price.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            pricingTitle
            pricingSubText
            formTitle
            moduleSelectorTitle
            priceFactorsTitle
            hubSpotFormId
            buttonText
            pricingFactors {
              name
              image
              altText
              labelPlaceHolder
              hubspotFormFieldName
            }
            pricingSubSections {
              type
              Label
              title
              offerings {
                offer {
                  title
                  text
                  altImage
                  image
                }
              }
              sectionTitle
              partnerLogos {
                logo
                altText
              }
              section {
                alignment
                label
                color
                background
                title
                image
                altText
                subText
              }
              questions {
                question {
                  questionLabel
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
