import React, {CSSProperties, FormEvent} from 'react';
import {ModuleInfo} from '../../models/module-info';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { hubspotFormSubmit, setBasicHubspotFormFields } from '../../helpers/hs-form-submit';
import { PricingFactors } from '../../models/pricing-factors';
import GdprConsent from '../shared/gdpr-consent';
import { ContactFormConfig } from '../../models/contact-form-config';
import {device} from '../../models/devices';

const Header = styled.header`
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 16px;
  
  &.center {
    text-align: center;
  }

  @media screen and ${device.tablet} {
    &, &.center {
      text-align: left;
    }
  }
`;

const inputStyle: CSSProperties = {
  padding: '17px 16px',
  borderRadius: 4,
  border: 'solid 1px #e3e7eb',
  backgroundColor: '#fcfdfe',
  fontSize: 14,
  fontWeight: 700,
  color: '#54595a',
  minWidth: 0,
  scrollSnapMarginTop: 90,
  scrollMarginTop: 90,
  gridColumn: 'span 2'
};

const Textarea = styled.textarea`
  padding: 17px 16px;
  border-radius: 4px;
  border: solid 1px #e3e7eb;
  background-color: #fcfdfe;
  font-size: 14px;
  font-weight: 700;
  color: #54595a;
  min-width: 0;
  scroll-snap-margin-top: 90px;
  scroll-margin-top: 90px;
  resize: none;
  grid-column: span 4;
  width: 100%;
  box-sizing: border-box;

  @media screen and ${device.tablet} {
    grid-column: span 2;
  }
`;

const buttonStyle: CSSProperties = {
  borderRadius: 8,
  backgroundColor: '#4231a9',
  width: '100%',
  height: 48,
  border: 'none',
  fontSize: 14,
  fontWeight: 600,
  color: 'white',
  cursor: 'pointer'
};

const Grid = styled.div`
  margin: 29px 0 35px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const ModuleItem = styled.label`
  &:nth-last-child(-n + 2) {
    grid-column: span 2;
  }
  
  @media screen and ${device.tablet} {
    &:last-child {
      grid-column: span 1;
    }
  }

  input {
    display: none;
    &:checked + .module-label {
      border-color: #4231a9;
      color: #444950;
      img {
        filter: initial;
      }
    }
  }
  .module-label {
    font-size: 14px;
    font-weight: 500;
    color: #7e868c;
    border-radius: 4px;
    border: solid 1px #e3e7eb;
    background-color: #fcfdfe;
    padding: 8px 16px;
    cursor: pointer;
    img {
      vertical-align: middle;
      margin-right: 8px;
      filter: grayscale(100%);
    }
  }
`;

const PriceFactor = styled.div`
  padding: 32px 17px;
  border-radius: 4px;
  border: solid 1px #e3e7eb;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1px;

  img {
    width: 100px;
    margin: 0 auto 13px;
  }
  
  span {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #54595a;
    display: block;
    margin-bottom: 32px;
  }
  
  input {
    padding: 14px 14px 14px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    border: solid 1px #e3e7eb;
    background-color: #fcfdfe;
    scroll-snap-margin-top: 90px;
    scroll-margin-top: 90px;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media screen and ${device.tablet} {
    margin-bottom: 12px;
    margin-right: 0 !important;
    width: auto;
  }
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 5px;
  
  @media screen and ${device.tablet} {
    font-size: 24px;
    line-height: 1.5em;
    margin-bottom: 10px;
  }
`;

const Text = styled.div`
  font-size: 18px;
  color: #7b85a0;
  text-align: center;
  letter-spacing: -0.16px;
  margin-bottom: 45px;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const PriceFactorsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;

  @media screen and ${device.tablet} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const handleSubmit = (e: FormEvent, modules: Array<ModuleInfo>, pricingFactors: Array<PricingFactors>, portalId: number, formId: string, contactFormConfig: ContactFormConfig) => {
  if (e) e.preventDefault();

    const data = new FormData(e.target as HTMLFormElement);
    const pricingFields = [...pricingFactors.filter((factor) => data.get(factor.name)).map(m => {
      return {
        name: m.hubspotFormFieldName,
        value: data.get(m.name),
      };
    })];
    const form = setBasicHubspotFormFields(data, modules, contactFormConfig);
    form.fields = [
      ...form.fields,
      ...pricingFields
    ];
    hubspotFormSubmit(portalId,formId, form);
};
export const Form = (props: {
  modules: Array<ModuleInfo>;
  pricingTitle: string;
  pricingSubText: string;
  formTitle: string;
  moduleSelectorTitle: string;
  priceFactorsTitle: string;
  hubSpotFormId: string;
  hubSpotPortalId: number;
  buttonText: string;
  contactFormConfig: ContactFormConfig;
  pricingFactors: Array<PricingFactors>
}): JSX.Element => {
  return (
    <React.Fragment>
      <Title>
        <ReactMarkdown>{props.pricingTitle}</ReactMarkdown>
      </Title>
      <Text>
        <ReactMarkdown>{props.pricingSubText}</ReactMarkdown>
      </Text>
      <form onSubmit={e => handleSubmit(e, props.modules, props.pricingFactors, props.hubSpotPortalId, props.hubSpotFormId, props.contactFormConfig)}>
        <Header className={'center'}><ReactMarkdown>{props.formTitle}</ReactMarkdown></Header>
        <Grid>
          <input
            style={inputStyle}
            type="text"
            name="firstName"
            placeholder="First Name"
            required
          />
          <input
            style={inputStyle}
            type="text"
            name="lastName"
            placeholder="Last Name"
            required
          />
          <input
            style={inputStyle}
            type="email"
            name="email"
            placeholder="Email Address"
            required
          />
          <input
            style={inputStyle}
            type="text"
            name="company"
            placeholder="Company"
            required
          />
          <Textarea
            name="message"
            placeholder="Message"
          />
        </Grid>
        <Header style={{gridArea: '1 / 1 / 1 / 5'}}>
          <ReactMarkdown>{props.moduleSelectorTitle}</ReactMarkdown>
        </Header>
        <Grid style={{border: 'none', padding: 0, marginBottom: 39}}>
          {props.modules.map((module, index) => (
            <ModuleItem key={index}>
              <input name={module.name} type="checkbox" value={module.name} defaultChecked={true}/>
              <div className={'module-label'}>
                <img src={module.icon} alt={module.altText} style={{width: 32, height: 32}}/>
                {module.name}
              </div>
            </ModuleItem>
          ))}
        </Grid>
        <section>
          <Header>
            <ReactMarkdown>{props.priceFactorsTitle}</ReactMarkdown>
          </Header>
          <PriceFactorsContainer>
            {props.pricingFactors.map((factor, index) => (
              <PriceFactor key={index}>
                <img src={factor.image} alt={factor.altText} />
                <span>{factor.name}</span>
                <input
                  name={factor.name}
                  type="number"
                  placeholder={factor.labelPlaceHolder}
                  required
                />
              </PriceFactor>
            ))}
          </PriceFactorsContainer>
        </section>
        <GdprConsent contactFormConfig={props.contactFormConfig} />
        <button style={buttonStyle} type="submit">
          <ReactMarkdown>{props.buttonText}</ReactMarkdown>
        </button>
      </form>
    </React.Fragment>
  );
};
