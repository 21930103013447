import React, {CSSProperties} from 'react';
import Arrow from '../../images/right.svg';

const arrowStyle: CSSProperties = {
  background: 'white',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  borderRadius: '50%',
  height: 40,
  width: 40
};

export const Arrows = (props: {
  onLeft?: () => void,
  onRight?: () => void,
}): JSX.Element => {
  return (
    <React.Fragment>
      <button style={{...arrowStyle, transform: 'scaleX(-1)', marginRight: 16}} onClick={() => props.onLeft?.()}>
        <img src={Arrow} alt="prev"/>
      </button>
      <button style={arrowStyle} onClick={() => props.onRight?.()}>
        <img src={Arrow} alt="next"/>
      </button>
    </React.Fragment>
  );
};
