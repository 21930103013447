import React from 'react';
import {ModuleInfo} from '../../models/module-info';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import { Form } from './form';
import ReactMarkdown from 'react-markdown';
import { PricingFactors } from '../../models/pricing-factors';
import { ContactFormConfig } from '../../models/contact-form-config';
import {device} from '../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat top center;
  background-size: contain;
  padding-bottom: 152px;
  padding-top: 184px;

  @media screen and ${device.tablet} {
    background-size: auto 748px;
    padding: 115px 24px 80px;
  }
`;

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const FormWrapper = styled.div`
  padding: 62px 100px 50px 99px;
  box-shadow: 6px 12px 34px 0 rgba(0, 0, 0, 0.04);
  background: white;
  border-radius: 30px;
  max-width: 970px;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 1;
  position: relative;

  @media screen and ${device.tablet} {
    padding: 53px 24px 32px;
  }
`;

const Header = styled.h1`
  font-size: 48px;
  color: white;
  font-weight: 500;
  width: 100%;
  max-width: 600px;
  line-height: 1.37em;
  letter-spacing: -0.3px;
  text-align: center;
  margin: 0 auto 42px;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5;
    text-align: left;
    max-width: initial;
    margin-bottom: 19px;
  }
`;

export const TitleSection = (props: {
  header: string;
  modules: Array<ModuleInfo>;
  pricingTitle: string;
  pricingSubText: string;
  formTitle: string;
  moduleSelectorTitle: string;
  priceFactorsTitle: string;
  hubSpotFormId: string;
  hubSpotPortalId: number;
  contactFormConfig: ContactFormConfig;
  buttonText: string;
  pricingFactors: Array<PricingFactors>
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.header}</ReactMarkdown></Header>
        <FormWrapper>
          <Form
            hubSpotFormId={props.hubSpotFormId}
            hubSpotPortalId={props.hubSpotPortalId}
            modules={props.modules}
            pricingFactors={props.pricingFactors}
            formTitle={props.formTitle}
            moduleSelectorTitle={props.moduleSelectorTitle}
            priceFactorsTitle={props.priceFactorsTitle}
            pricingSubText={props.pricingSubText}
            pricingTitle={props.pricingTitle}
            buttonText={props.buttonText}
            contactFormConfig={props.contactFormConfig}
          />
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};
