import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import Background from '../../images/featurebg.svg';
import ReactMarkdown from 'react-markdown';
import Slider from 'infinite-react-carousel';
import {Arrows} from '../shared/arrows';

const Container = styled.section`
  background-image: url(${Background});
  height: auto;
  margin-bottom: 80px;
`;

const Label = styled.h2`
  color: #47b359;
  text-transform: uppercase;
  background-color: rgba(113, 192, 85, 0.32);
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 3px;
  display: inline-block;
  margin: 0;
`;

const Header = styled.span`
  font-weight: 500;
  color: #4f40ab;
  width: 270px;
  display: block;
  font-size: 32px;
  line-height: 1.5em;
  margin: 8px auto 23px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 80px;
`;

const HeaderContainer = styled.div`
  text-align: center;
  padding: 0 24px;
  margin-bottom: 24px;
`;

const offerItemStyle: CSSProperties = {
  padding: '32px 33px 24px 32px',
  borderRadius: 13,
  boxShadow: '8px 7px 14px rgba(192, 202, 209, 0.21)',
  backgroundColor: 'white',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'flex-start',
  margin: '0 24px 80px',
};

const offerHeaderStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 500,
  color: '#4231a9',
  letterSpacing: -0.2,
  marginBottom: 9
};

const offerTextStyle: CSSProperties = {
  fontSize: 16,
  lineHeight: 1.75,
  color: '#54595a',
  letterSpacing: -0.1
};

const SliderContainer = styled.div`
  flex: 1;
  overflow: hidden;
  .carousel-item {
    height: auto;
  }
`;

export const BenefitsMobile = (props: {
  title: string;
  label: string;
  offers?: Array<{
    title: string;
    text: string;
    altImage: string;
    image: string;
  }>
}): JSX.Element => {

  const sliderRef = React.createRef<{
    slickNext: () => void;
    slickPrev: () => void;
    slickGoTo: () => void;
    slickPause: () => void;
    slickPlay: () => void;
  }>();

  const changeSlide = (offset: number) => {
    // setActiveIndex(activeIndex + offset);
    if (offset > 0) {
      sliderRef.current?.slickNext();
    } else {
      sliderRef.current?.slickPrev();
    }
  };

  return (
    <Container className={'mobile-only'}>
      <Wrapper>
        <HeaderContainer>
          <Label><ReactMarkdown>{props.label}</ReactMarkdown></Label>
          <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
          <Arrows onLeft={() => changeSlide(-1)} onRight={() => changeSlide(+1)} />
        </HeaderContainer>
        <SliderContainer>
          <Slider
            ref={sliderRef}
            slidesToShow={1}
            arrows={false}
            virtualList={true}
            adaptiveHeight={true}
          >
            {
              props.offers?.map((offer, index) => (
                <div key={index}>
                  <div style={offerItemStyle}>
                    <img style={{marginRight: 20}} src={offer.image} alt={offer.altImage}/>
                    <div>
                      <header style={offerHeaderStyle}>
                        <ReactMarkdown>{offer.title}</ReactMarkdown>
                      </header>
                      <div style={offerTextStyle}>
                        <ReactMarkdown>{offer.text}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </Slider>
        </SliderContainer>
      </Wrapper>
    </Container>
  );
};
