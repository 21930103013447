import React, {CSSProperties} from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import {device} from '../../models/devices';
import {RegularSectionModel} from '../../models/regular-section-model';

const Container = styled.section<{
  alignment: 'left' | 'right'
}>`
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  flex-direction: ${props => props.alignment === 'left' ? 'row' : 'row-reverse'};
  justify-content: space-between;
  padding: 120px 0;
  border-bottom: 1px solid #e6ecfb;

  @media screen and ${device.tablet} {
    padding: 80px 23px 32px;
    flex-direction: column-reverse;
    align-items: stretch;

    > img {
      margin-bottom: 33px;
    }
  }
`;

const Header = styled.h2`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 3px;
  display: inline-block;
  letter-spacing: 1.8px;

  @media screen and ${device.tablet} {
    margin: 0;
  }
`;

const Title = styled.div`
  color: #4f40ab;
  font-size: 48px;
  font-weight: 500;
  line-height: 1.38em;
  margin-top: 15px;
  margin-bottom: 21px;
  
  @media screen and ${device.tablet} {
    font-size: 32px;
    margin-top: 16px;
    margin-bottom: 30px;
  }
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 1.78em;
  color: #808384;

  @media screen and ${device.tablet} {
    font-size: 16px;
  }
`;

export const RegularSection = (props: RegularSectionModel['section'] & {
  style?: CSSProperties
}): JSX.Element => {
  return (
    <Container alignment={props.alignment} style={props.style}>
      <div style={{flexBasis: '40%'}}>
        <Header style={{
          color: props.color,
          backgroundColor: props.background,
        }}><ReactMarkdown>{props.label}</ReactMarkdown></Header>
        <Title>
          <ReactMarkdown>{props.title}</ReactMarkdown>
        </Title>
        <Text>{props.subText}</Text>
      </div>
      <img style={{flexBasis: '57%'}} src={props.image} alt={props.altText}/>
    </Container>
  );
};
