import React, {CSSProperties} from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import {device} from '../../models/devices';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  text-transform: uppercase;
  color: #7b85a0;
  margin-bottom: 60px;
  &:before, &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #e6ecfb;
    margin: auto;
  }
  &:before {
    margin-right: 10px
  }
  &:after {
    margin-left: 10px
  }
  
  @media screen and ${device.tablet} {
    font-size: 14px;
    letter-spacing: -0.1px;
    margin-bottom: 39px;
  }
`;

const LogoList = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 992px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 24px;
  
  @media screen and ${device.tablet} {
    max-width: 500px;
  }
`;

const LogoWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 81px;
  }
  
  @media screen and ${device.tablet} {
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    margin-bottom: 24px;
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const containerStyle: CSSProperties = {
  maxWidth: 1171,
  margin: '0 auto',
  borderBottom: '1px solid #e6ecfb',
  paddingBottom: 60
};

export const PartnersList = (props: {
  title: string;
  logos: Array<{
    logo: string;
    altText: string;
  }>
}): JSX.Element => {
  return (
    <section style={containerStyle}>
      <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
      <LogoList>
        {props.logos.map(({logo, altText}, index) => (
          <LogoWrapper key={index}>
            <img style={{width: '100%'}} src={logo} alt={altText}/>
          </LogoWrapper>
        ))}
      </LogoList>
    </section>
  );
};
