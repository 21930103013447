import React, {CSSProperties} from 'react';
import ReactMarkdown from 'react-markdown';
import Background from '../../images/featurebg.svg';

const containerStyle: CSSProperties = {
  backgroundImage: `url(${Background})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  textAlign: 'center',
  paddingBottom: 120,
  marginBottom: 122
};

const labelStyle: CSSProperties = {
  color: 'white',
  textTransform: 'uppercase',
  backgroundColor: '#67dffa',
  fontSize: 12,
  fontWeight: 500,
  padding: '4px 12px',
  borderRadius: 3,
  display: 'inline-block',
  marginTop: 120,
  marginBottom: 10
};

const headerStyle: CSSProperties = {
  color: '#4f40ab',
  fontSize: 48,
  fontWeight: 500,
  maxWidth: 630,
  margin: '0 auto',
  textAlign: 'center',
  marginBottom: 60,
  padding: '5px 14.5px 4px'
};

const gridStyle: CSSProperties = {
  display: 'grid',
  gridGap: 30,
  gridTemplateColumns: '1fr 1fr',
  maxWidth: 1170,
  margin: '0 auto'
};

const offerItemStyle: CSSProperties = {
  padding: '32px 33px 24px 32px',
  borderRadius: 13,
  boxShadow: '8px 7px 14px 0 #d8ecf0',
  backgroundColor: 'white',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'flex-start'
};

const offerHeaderStyle: CSSProperties = {
  fontSize: 24,
  fontWeight: 500,
  color: '#4231a9',
  letterSpacing: -0.2,
  marginBottom: 3
};

const offerTextStyle: CSSProperties = {
  fontSize: 16,
  lineHeight: 1.75,
  color: '#54595a',
  letterSpacing: -0.1
};

export const BenefitsDesktop = (props: {
  title: string;
  label: string;
  offers?: Array<{
    title: string;
    text: string;
    altImage: string;
    image: string;
  }>
}): JSX.Element => {
  return (
    <section className={'desktop-only'} style={containerStyle}>
      <div style={labelStyle}><ReactMarkdown>{props.label}</ReactMarkdown></div>
      <h2 style={headerStyle}><ReactMarkdown>{props.title}</ReactMarkdown></h2>
      <div style={gridStyle}>
        {props.offers?.map((offer, index) => (
          <div style={offerItemStyle} key={index}>
            <img style={{marginRight: 20}} src={offer.image} alt={offer.altImage}/>
            <div>
              <header style={offerHeaderStyle}>
                <ReactMarkdown>{offer.title}</ReactMarkdown>
              </header>
              <div style={offerTextStyle}>
                <ReactMarkdown>{offer.text}</ReactMarkdown>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
