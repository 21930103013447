import React from 'react';
import ReactMarkdown from 'react-markdown';
import Accordion from '../shared/accordion';
import styled from 'styled-components';
import {device} from '../../models/devices';

const Container = styled.section`
  background-position: center;
  background-size: cover;
  max-width: 1170px;
  margin: 0 auto 120px;
  padding-top: 120px;

  @media screen and ${device.tablet} {
    padding: 80px 24px 0;
    text-align: center;
    margin-bottom: 80px;
  }
`;

const Label = styled.h2`
  color: #0089ff;
  text-transform: uppercase;
  background-color: #e3f5fd;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 15px;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 10px;
  letter-spacing: 2.1px
`;

const Title = styled.div`
  color: #4f40ab;
  font-size: 48px;
  font-weight: 500;
  max-width: 630px;
  margin: 0 0 41px;
  
  @media screen and ${device.tablet} {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const FaqContainer = (props: {
  title?: string;
  label?: string;
  questions?: Array<{
    questionLabel: string;
    text: string;
  }>
}): JSX.Element => {
  return (
    <Container>
      <Label>{ props.label && <ReactMarkdown>{props.label}</ReactMarkdown> }</Label>
      <Title>{ props.title && <ReactMarkdown>{props.title}</ReactMarkdown> }</Title>
      <div style={{textAlign: 'left'}}>
        {
          props.questions?.map((question, index) => (
            <Accordion key={index} title={question.questionLabel} content={question.text}/>
          ))
        }
      </div>
    </Container>
  );
};
