import React from 'react';
import {BenefitsDesktop} from './benefits-desktop';
import {BenefitsMobile} from './benefits-mobile';

export const Benefits = (props: {
  title: string;
  label: string;
  offers?: Array<{
    title: string;
    text: string;
    altImage: string;
    image: string;
  }>
}): JSX.Element => {

  const isSSR = typeof window === 'undefined';

  return (
    <React.Fragment>
      <BenefitsDesktop title={props.title} label={props.label} offers={props.offers} />
      {!isSSR && <BenefitsMobile title={props.title} label={props.label} offers={props.offers} />}
    </React.Fragment>
  );
};
