import React from 'react';
import {useRef, useState} from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Chevron from '../../images/chevron.svg';
import {device} from '../../models/devices';

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d8d8d8;
  padding: 23px 0;
`;

const AccordionHeader = styled.button<{isOpen: boolean}>`
  color: #444;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  
  &:before {
    content: '';
    display: inline-block;
    height: 21px;
    width: 11px;
    flex-shrink: 0;
    flex-grow: 0;
    transform: ${props => props.isOpen ? 'rotate(90deg)' : ''};
    transition: transform 0.6s ease;
    transform-origin: center;
    background: url(${Chevron}) no-repeat center left;
  }
`;

const AccordionTitle = styled.header`
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  color: black;
  padding-left: 28px;

  @media screen and ${device.tablet} {
    line-height: 32px;
  }
`;

const AccordionContent = styled.div`
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #54595a;
  padding-left: 40px;
  line-height: 1.84em;

  p {
    margin: 0 0 16px;
  }
  
  > :first-child {
    margin-top: 8px;
  }
  
  > :last-child {
    margin-bottom: 0;
  }

  @media screen and ${device.tablet} {
    line-height: 28px;
  }
`;

export const Accordion = (props: {
  title: string;
  content: string;
}): JSX.Element => {
  const [activeState, setActiveState] = useState<'active' | ''>('');
  const [height, setHeight] = useState('0px');

  const content = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setActiveState(activeState === '' ? 'active' : '');
    setHeight(
      activeState === 'active' ? '0px' : `${content.current?.scrollHeight}px`
    );
  }

  return (
    <AccordionSection>
      <AccordionHeader isOpen={activeState === 'active'} className={activeState} onClick={toggleAccordion}>
        <AccordionTitle><ReactMarkdown>{props.title}</ReactMarkdown></AccordionTitle>
      </AccordionHeader>
      <AccordionContent
        ref={content}
        style={{ maxHeight: `${height}` }}
      >
        <ReactMarkdown>{props.content}</ReactMarkdown>
      </AccordionContent>
    </AccordionSection>
  );
};

export default Accordion;
